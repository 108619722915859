import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

const Mlogin = () => import("../views/mlogin.vue");
const MAccount = () => import("../views/account.vue");
const ReportAdmin = () => import("../views/report.vue");
const Message = () => import('../views/message.vue')
const Complaint = () => import("../views/complaint.vue");

const Play = () => import('../components/Message/play.vue')//添加演出
// const Play = () => import('../components/Message/sellDetail.vue')//添加演出
const Screens = () => import('../components/Message/screens.vue')//出票
// const Screens = () => import('../components/Message/sellDetailNew.vue')//出票
const Plan = () => import('../components/Message/plan.vue')//影厅
const Staff = () => import('../components/Message/staff.vue')//账户 = account
const Order = () => import("../components/Message/order.vue");//出票
const Refund = () => import("../components/Message/refund.vue");//退票
const RefundMana = () => import("../components/Message/refundMana.vue");//退票
const Customer = () => import("../components/Message/customer.vue")//顾客
const Count = () => import('../components/Message/count.vue')//分析
const Report = () => import("../components/Message/report.vue");//报表
const Detalied = () => import('../components/Message/detalied.vue')//
const SellDetail = () => import("../components/Message/sellDetail.vue");//影院售出详情
const Ticket = () => import("../components/Message/ticket.vue");//记忆打印
const Goods = () => import("../components/Message/goods.vue");//景区
const YlGoods = () => import("../components/Message/ylGoods.vue");//瑶里打印票
const Sell = () => import("../components/Message/sellDetailNew.vue");//出票
const SellO = () => import("../components/Message/sell.vue");//出票
const Convert = () => import("../components/Message/convert.vue");//
const Account = () => import("../components/Message/account.vue");//账户 = staff
const Finance = () => import("../components/Message/finance.vue");//财务
const FinanceDetail = () => import("../components/Message/financeDetail.vue");//财务
const Channel = () => import("../components/Message/channel.vue");//渠道
const ReportJY = () => import("../components/Message/report_jiyi.vue");//渠道
const FenXiao = () => import("../components/Message/fenxiao.vue");//渠道
const SellSim = () => import("../components/Message/sellDetailSim.vue");//渠道售票



Vue.use(VueRouter)

const routes = [
  {
    path: "",
    component: Mlogin,
    meta: {
      title: "vendorpc",
    },
    children: [],
  },
  {
    path: "/mlogin",
    component: Mlogin,
    meta: {
      title: "管理员登录",
    },
  },
  {
    path: "/report",
    component: ReportAdmin,
    meta: {
      title: "票务统计",
    },
  },
  {
    path: "/account",
    component: MAccount,
    meta: {
      title: "票务统计",
    },
  },
  {
    path: "/complaint",
    component: Complaint,
    meta: {
      title: "客户投诉",
    },
  },
  {
    path: "/message",
    component: Message,
    meta: {
      title: "管理员界面",
    },
    children: [
      {
        path: "/",
        redirect: "/message/count",
      },
      {
        path: "play",
        component: Play,
      },
      {
        path: "order",
        component: Order,
      },
      {
        path: "screens",
        component: Screens,
      },
      {
        path: "plan",
        component: Plan,
      },
      {
        path: "staff",
        component: Staff,
      },
      {
        path: "customer",
        component: Customer,
      },
      {
        path: "count",
        component: Count,
      },
      {
        path: "report",
        component: Report,
      },
      {
        path: "detalied",
        component: Detalied,
      },
      {
        path: "sellDetail",
        component: SellDetail,
      },
      {
        path: "ticket",
        component: Ticket,
      },
      {
        path: "goods",
        component: Goods,
      },
      {
        path: "ylGoods",
        component: YlGoods,
      },
      {
        path: "sell",
        component: Sell,
      },
      {
        path: "sellSim",
        component: SellSim,
      },
      {
        path: "convert",
        component: Convert,
      },
      {
        path: "account",
        component: Account,
      },
      {
        path: "refund",
        component: Refund,
      },
      {
        path: "refundMana",
        component: RefundMana,
      },
      {
        path: "finance",
        component: Finance,
      },
      {
        path: "financeDetail",
        component: FinanceDetail,
      },
      {
        path: "channel",
        component: Channel,
      },
      {
        path: "reportjy",
        component: ReportJY,
      },
      {
        path: "fenxiao",
        component: FenXiao,
      },
      {
        path:'sellO',
        component:SellO
      }
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: 'hash',
})

// router.beforeEach((to, from, next) => {
//   // beforeEach是router的钩子函数，在进入路由前执行
//    if (to.meta.title) { //判断是否有标题
//     console.log(to.meta.title)
//     document.title = to.meta.title } else { document.title = '默认title' } next() })

router.beforeEach((to, from, next) => {

    // document.title =  to.matched[0].meta.title
    document.title = '景德镇智慧旅游平台'
    // const favicon = to.meta.favicon || defaultFavicon;
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    console.log(link)
    link.rel = 'shortcut icon';
    link.type = 'image/png';
    link.href = 'public/favicon.png';
    head.appendChild(link);
    console.log(head)

  // const test = document.querySelector('link[rel="icon"]') || document.querySelector('link[rel="shortcut icon"]');
  // link.setAttribute('href','public/favicon.png');

  next();


  // console.log(to.path.split('/')[1]);
  //未登录强制跳转到登录页
  // if (to.path === '/mlogin' || to.path === '/complaint')
  //   return next()
  // const tokenStr = window.localStorage.getItem('token')
  // if (!store.state.UserToken) {
  //   // 未登录  判断页面是否要登录meta
  //   return next('/mlogin')
  // } else {
  //   //用户已经登录处理权限
  //   if (to.path === '/message/staff') {
  //     if (store.state.UserToken != "0") {
  //       alert("权限不够")
  //       return next(from.path)
  //     }
  //   }
  // }
})

export default router
